<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="选择时间">
          <el-date-picker
            v-model="formInline.time"
            clearable
            type="daterange"
            :picker-options="pickerOptions"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%; margin-top: 20px"
      stripe>
      <el-table-column prop="xuhao" label="序号" align="center" ></el-table-column>
      <el-table-column prop="nickname" label="昵称" align="center" ></el-table-column>
      <el-table-column prop="pointnum" label="增加积分" align="center" ></el-table-column>
      <el-table-column prop="pointdescribe" label="增加积分原因" align="center" ></el-table-column>
      <el-table-column label="审核状态" align="center" >
        <template slot-scope="scope">
          <span>{{ backCheckStatusLabel(scope.row.auditstatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核描述" prop="auditdesc" align="center"></el-table-column>
      <el-table-column label="操作" width="200" align="center">
          <div
            slot-scope="scope"
            style="display: flex; flex-wrap: wrap; justify-content: center">
            <el-button type="primary" @click="auditPointFn(scope.row)" :disabled="Boolean(scope.row.auditstatus)">审核</el-button>
          </div>
      </el-table-column>
    </el-table>
    <div class="fenyekuang">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>
    <!-- 审核积分 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="auditPointDialog"
      title="审核积分"
      width="600px"
			:before-close="auditPointhandleClose"
    >
      <auditPoint :pointrecordid="accPointUser" @closeAuditPointDialog="closeAuditPointDialog" ref="auditpointRef"></auditPoint>
    </el-dialog>
  </div>
</template>

<script>
import { examinePointList } from '@/api/examinePoint/examinePoint.js'
import auditPoint from './auditPoint'
export default {
  data () {
    return {
      choiceDate0: '',
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate:
        (time) => {
          let choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
          const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
          const min = minTime;
          const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
          const max = newDate < maxTime ? newDate : maxTime;
          //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          //若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      formInline: {
        time: '',
      },
      tableData: [],
      currentPage: 1, //当前页
      fenye: {
        pagesizes: [10, 20, 50],
        pagesize: 10,
        totalnum: 0,
      }, //分页数据
      examineStatus: [{
        value: null,
        label: '待审核' //已申请
      },{
        value: 0,
        label: '待审核' //已申请
      },{
        value: 1,
        label: '审核通过'
      },{
        value: 2,
        label: '审核不通过'
      }],
      accPointUser: '',
      auditPointDialog: false,
    };
  },

  components: {
    auditPoint
  },

  computed: {},

  mounted(){
    this.getList();
  },

  methods: {
    //返回状态文本
    backCheckStatusLabel(val){
      let item = this.examineStatus.filter(item => item.value === val)
      return item[0].label;
    },
    getList() {
      examinePointList({
        pageSize: this.fenye.pagesize,
        pageNum: this.currentPage,
        startDate: this.formInline.time[0] || '',
        endDate: this.formInline.time[1] || ''
      }).then(res => {
        if(res.result == 200) {
          res.detail.list.map((item, index) => {
            item.xuhao = index + 1;
          });
          this.tableData = res.detail.list;
          this.fenye.totalnum = res.detail.totalRow;
        }
      })
    },
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    //展示条数改变时
    sizeChange(val) {
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //当前页改变时
    currentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //打开审核积分弹框
    auditPointFn(row) {
			this.accPointUser = row.pointrecordid.toString();
      this.auditPointDialog = true;
    },
		//取消审核积分操作
		closeAuditPointDialog(data){
			// console.log(data)
			this.auditPointDialog = false;
			if(data == 'add'){
				this.onSearch();
			}
		},
    //关闭审核积分弹框
    auditPointhandleClose(done) {
      this.$refs.auditpointRef.resetForm();
			done()
    },
    examinePoint(row, num) {
      console.log("row:", row);
    },
  }
}

</script>
<style lang='scss' scoped>
.fenyekuang {
  width: 100%;
  height: 50px;
  margin-top: 1px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>