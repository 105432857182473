import request from '@/utils/request'

// 待实名审核列表
export function examinePointList(params) {
  return request({
    url: '/MA_UserAccount/getPointAdditPage',
    method: 'get',
    params:params
  })
}

// 第一步审核
export function examinePointOnce(data) {
  return request({
    url: '/MA_UserAccount/additPoint',
    method: 'post',
    data: data
  })
}

// 第二步审核
export function examinePointTwice(data) {
  return request({
    url: '/MA_UserAccount/additsecPoint',
    method: 'post',
    data: data
  })
}